import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import CTA from "@v4/talend/src/components/cta/Cta";

import * as guideStyles from "./styleguide.module.css";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { I18nProvider } from "@v4/utils/i18nContext";

export const query = graphql`
    query StyleGuideQuery {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: "en" }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: "en" }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: "en" }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: "en" }) {
            ...FooterSubMenu
        }
    }
`;

const Styleguide = ({ data }) => {
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    return (
        <I18nProvider translatedPaths={{ en: `/styleguide` }} locale={`en`}>
            <>
                <Layout headerMenu={data.contentfulMenu} footerMenu={footerData}>
                    <SEO title="This is Styleguide LITE." />
                    <Helmet bodyAttributes={{ class: "hasOpaqueHeader" }} />
                    <div className={guideStyles.styleguide}>
                        <div className="container">
                            <small className={guideStyles.introHeading}>Heading 1</small>
                            <small>v4</small>
                            <h1> Styleguide Lite&trade;</h1>

                            <section>
                                <p>
                                    Laboris pariatur tempor ad voluptate proident ea ad elit. Sit sint in id incididunt.
                                    Irure veniam sint culpa officia magna. Sint deserunt qui reprehenderit esse proident
                                    veniam aute exercitation consequat nostrud eu est aliquip. Labore ea elit et sit
                                    sunt. Anim veniam pariatur do fugiat officia non duis consectetur. Consequat
                                    exercitation sunt proident reprehenderit id duis enim.
                                </p>

                                <small className={guideStyles.introHeading}>Heading 2</small>
                                <h2>Four hundred salmon can't be wrong</h2>

                                <small className={guideStyles.introHeading}>Heading 3</small>
                                <h3>Maurice had a few excellent holidays</h3>

                                <small className={guideStyles.introHeading}>Heading 4</small>
                                <h4>Transactional liquidity sounds fancy</h4>

                                <small className={guideStyles.introHeading}>Anchors</small>
                                <p>
                                    <a href="https://www.talend.com/">Talent with a D</a>
                                </p>

                                <small className={guideStyles.introHeading}>Lists</small>
                                <h5>Unordered</h5>
                                <ul>
                                    <li>Item 1</li>
                                    <li>Item 2</li>
                                    <li>Item 3</li>
                                    <li>Item 4</li>
                                </ul>
                            </section>

                            <section>
                                <div className={guideStyles.cta}>
                                    <CTA title="Primary Button" variant="Primary" url="https://www.google.com" />
                                    <CTA title="Secondary Button" variant="Secondary" url="/404" />
                                    <CTA title="Tertiary Button" variant="Tertiary" url="#top" />
                                    <CTA title="Link Button" variant="Link" url="/" />
                                    <CTA title="Arrow Link" variant="ArrowLink" url="/" />
                                </div>
                            </section>

                            <section>
                                <small className={guideStyles.introHeading}>Gradients</small>
                                <div className={guideStyles.gradientGrid}>
                                    <div className={`${guideStyles.gradient} bgGradientDusk`}>
                                        <span>Dusk</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientIndigoSlate`}>
                                        <span>IndigoSlate</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientSeashelltoWhite`}>
                                        <span>SeashelltoWhite</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientSunnySky`}>
                                        <span>SunnySky</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientVintageViolet`}>
                                        <span>VintageViolet</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientIndigoRain`}>
                                        <span>IndigoRain</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientEveningBlues`}>
                                        <span>EveningBlues</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientLightForest`}>
                                        <span>LightForest</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientProductGradient`}>
                                        <span>ProductGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientCustomerGradient`}>
                                        <span>CustomerGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientSolutionGradient`}>
                                        <span>SolutionGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientResourceGradient`}>
                                        <span>ResourceGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientPartnerGradient`}>
                                        <span>PartnerGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientBrandCleanGradient`}>
                                        <span>BrandCleanGradient</span>
                                    </div>
                                    <div className={`${guideStyles.gradient} bgGradientBrandCleanGradientReverse`}>
                                        <span>BrandCleanGradientReverse</span>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </Layout>
            </>
        </I18nProvider>
    );
};

export default Styleguide;
